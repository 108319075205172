.landingButtons button.ant-btn-primary {
  background: #1570ef !important;
  border-radius: 8px;
  height: 44px;
  font-weight: 500;
}
.landingButtons button.ant-btn-default {
  border-radius: 8px;
  height: 44px;
  font-weight: 500;
}
input[type="tel"] {
  padding: 4px 11px;
}
.affiliate-landing-banner {
  background-image: url("../../images/affiliateLandingImages/affiliate-landing-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* .ant-row {
  display: flex !important;
  flex-flow: row wrap !important;
  min-width: 0 !important;
  height: initial;
  margin-right: unset !important;
  margin-left: unset !important;
  zoom: unset !important;
} */
.ant-row::after {
  clear: none !important;
}
.ant-row::after,
.ant-row::before {
  display: flex !important;
  content: none !important;
}
/* .justify-content-between {
  justify-content: space-between !important;
  width: revert !important;
} */
.ant-card {
  min-height: initial !important;
}
@media only screen and (max-width: 768px) {
  .affalitte-marketing-mobile h1 {
    font-size: 30px !important;
  }
  .affalitte-marketing-mobile p {
    width: 100% !important;
  }
  .mobile-iframe {
    height: auto;
  }
  .m-w-100 {
    width: 100%;
  }
  .ratting-review {
    font-size: 24px !important;
  }
  .ratting-review-content {
    font-size: 18px !important;
  }
  .font-28 {
    font-size: 24px !important;
  }
  .freetrilbox {
    width: 100% !important;
    top: 0px !important;
  }
  .footer-space-af {
    padding: 25px !important;
  }
  .crm-section {
    flex-direction: column-reverse;
  }
}
