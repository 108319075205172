.removeBorder .ant-card-head {
  border-bottom: none;
  font-weight: normal;
  padding: 0px 15px;
}

.ant-card {
  border-radius: 8px;
  min-height: 160px;
}

.removeBorder .ant-card-head svg {
  margin-right: 15px;
}

.removeBorder .ant-card-body {
  padding: 16px;
}

.fullWidth .ant-modal-content {
  width: 100% !important;
}

.test .ant-input-number {
  border: none;
  border-radius: 8px;
}

.my-referral-popup .ant-modal-title {
  text-align: left;
  color: black;
  margin-top: 5px;
}

.my-referral-popup .text-secondary p {
  color: var(--gray-500, #667085);
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.my-referral-popup .my-referral-default-method {
  color: var(--gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 21px;
}

.my-referral-popup .my-referrals-myBalance {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.my-referral-popup .my-referrals-myBalance span {
  width: 42%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-referral-popup .my-referral-balance-number {
  width: 102px;
  color: #484d59;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payout-modal-input {
  padding: 0px 10px !important;
  max-width: 106px;
  font-weight: 500;
}

.payout-balance-label,
.payout-requested-label {
  height: fit-content;
  margin: auto 10px;
}

.ant-form-item-explain-error {
  font-weight: normal;
  margin-bottom: 10px;
}

.fullWidth .ant-modal-body {
  padding-bottom: 24px !important;
}

.payout-balance-amount {
  height: 41px;
  font-size: 25px;
  font-weight: 500;
}

.payment-request-modal-popup .anticon-info-circle span {
  color: green;
}

.payment-request-modal-popup .ant-modal-confirm-btns .ant-btn {
  width: 102px;
  height: 44px;
  font-size: 16px;
}

.payment-request-modal-popup .anticon-info-circle svg {
  color: green;
  border-radius: 28px;
  border: 8px solid var(--success-50, #ecfdf3);
  background: var(--success-100, #d1fadf);
  width: 37px;
  height: 37px;
}

.successful-payment-text {
  color: #719d40;
}

.disable-btn {
  background: #bebebe !important;
  color: #ffffff !important;
  border-radius: 8px;
}

.affiliate-button[disabled] {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #f5f5f5 !important;
  border-radius: 8px;
}
