.affiliate-overview .ant-space-item:nth-child(1) {
  width: 80%;
}

.my-referrals-tbl .ant-table-cell {
  min-width: 160px;
}

.overview-table .ant-table-thead > tr > th {
  color: #667085;
  background: #fff !important;
  white-space: nowrap;
  width: auto !important;
}
.overview-table .ant-table-thead > tr > td {
  white-space: nowrap !important;
  width: fit-content !important;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #389fff;
  margin: 1px 0px 1px 4px;
}

.slider {
  padding: 0;
}
.slider .ant-slider-rail {
  height: 8px;
}
.slider .ant-slider-track {
  height: 8px;
}
.slider .ant-slider-step {
  height: 8px;
}
.slider .ant-slider-handle {
  height: 18px;
  width: 18px;
  border: solid 1px #2973e8;
}

.popUpOpen:hover {
  background-color: yellow;
}

.my-referrals-tbl-details .ant-table-body {
  overflow-y: auto !important;
}
.my-referrals-tbl-details .ant-table-cell {
  min-width: 160px;
}

.super-admin-table .ant-table-cell:last-child {
  min-width: 50px;
}

.affiliate-payment-tooltip {
  padding-bottom: 7px !important;
}
.affiliate-payment-tooltip .ant-tooltip-inner {
  background: #101828 !important;
  border-radius: 8px !important;
}

.affiliate-payment-tooltip .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #101828 !important;
}
