.payment-info-page .center-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-info-page .align-items-center .estimated-price {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.free-trial-remove-height {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
}

.free-trial-price-lineThrough,
.free-trial-price-lineThrough-special {
  position: relative;
}

.free-trial-price-lineThrough:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 11px;
  width: 107%;
  height: 0.1rem;
  background: #e40000;
  transform: rotate(168deg);
}

.free-trial-price-lineThrough-special:after {
  content: "";
  position: absolute;
  left: -4px;
  bottom: 16px;
  width: 115%;
  height: 0.1rem;
  background: #e40000;
  transform: rotate(168deg);
}

.free-trial-submit-button {
  border-radius: 8px;
  border: 1px solid var(--primary-600, #1570ef);
  background: var(--primary-600, #1570ef) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
  color: white !important;
}

.paymentGateway-first-row input,
.paymentGateway-first-row .payment-gateway-input {
  height: 49px;
  font-size: 18px;
}

.paymentGateway-first-row .payment-gateway-input .InputContainer .InputElement {
  font-size: 18px;
}

.paymentGateway-second-row .ant-space-item:nth-child(1) {
  height: 185px;
}

.paymentGateway-second-row .ant-space-item .center-card-image {
  height: 210px;
}

.paymentGateway-second-row .payment-info-page .payment-gateway-company div,
.paymentGateway-second-row .payment-info-page .payment-gateway-order div,
.paymentGateway-second-row .payment-info-page .payment-gateway-plan div {
  color: #344054;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 5px 0px;
}

@media (max-width: 1321px) {
  .paymentGateway-second-row .promoCode-row .promoCode-button {
    margin-left: 10px;
  }
}
