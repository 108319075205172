.promocode-main-row .ant-form-item {
  margin-bottom: 5px;
}

.promocode-main-row .ant-form-item .ant-form-item-control-input-content {
  margin-bottom: 0 !important;
}

.promocode-first-col .promocode-last-input-field .ant-form-item-label {
  padding-bottom: 4px;
}

.promocode-first-col .select-promo-code .ant-select-selector {
  border-radius: 8px;
}

.promocode-second-col .rdw-editor-main {
  border-radius: 8px;
  min-height: 213px;
  overflow: unset;
}

.promocode-second-col .rdw-editor-main .rdw-suggestion-dropdown {
  box-shadow: 0 0 10px 2px #f2f2f2;
}

.promocode-second-col .promocode-submit-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #1570ef);
  background: var(--primary-600, #1570ef);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.promocode-warning-modal .ant-modal-content {
  border-radius: 8px;
}

.promocode-warning-modal .warning-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 96px;
}

.promocode-warning-modal .fliter-sort-title-add-tab {
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
}

.promocode-warning-modal .promocode-warning-para {
  color: var(--gray-500, #667085);
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  margin-bottom: 18px;
}

.promocode-warning-modal .promocode-warning-modal-button button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
  border-radius: 8px;
  border: 1px solid #3483fa;
  background: #3483fa;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.userCard .tablecard .promocode-table-name-col .ant-table-column-sorters {
  width: 200px;
}

.userCard .tablecard .promocode-table-plan-col .ant-table-column-sorters {
  width: 150px;
}

.userCard .tablecard .promocode-table-internal-col .ant-table-column-sorters {
  width: 266px;
}

.userCard .tablecard .promocode-table-internal-col .user-list-name {
  padding: 0px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.termsconditions .ant-card-body {
  padding: 0px;
}

.promocode-internal-modal .viewNote {
  max-height: 200px;
  overflow: auto;
}

.promocode-internal-modal .ant-modal-content {
  border-radius: 8px;
}

.promocode-internal-modal .warning-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  margin: auto;
}

.promocode-internal-modal .fliter-sort-title-add-tab {
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
}

.promocode-internal-modal .promocode-internal-logoImage-col {
  width: 96%;
  padding: 0px !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.promocode-internal-modal
  .promocode-internal-logoImage-col
  .promocode-internal-logoImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
}

.promocode-internal-modal button {
  color: white;
  border-radius: 8px;
  border: 1px solid #3483fa;
  background: #3483fa;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.promocode-internal-modal .ant-btn-default {
  background: #fff;
  color: #000;
  border: 1px solid #d0d5dd;
}
.promocode-internal-modal .ant-btn-default:hover {
  background: #fff;
  color: #000;
}

.promocode-internal-modal button:hover {
  background: #3483fa;
  color: white;
}

.promemodal-fix button.ant-modal-close {
  top: 25px;
  right: 15px;
}
.promemodal-fix .ant-modal-content .ant-modal-body {
  padding-top: 0px;
}

.warningModal .warning-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 96px;
}

.warningModal .warning-note {
  color: var(--gray-500, #667085);
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  margin-bottom: 18px;
}

.warning-note .fliter-sort-title-add-tab {
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
}

.warning-note .warning-modal-button button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
  border-radius: 8px;
  border: 1px solid #3483fa;
  background: #3483fa;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.warning-note .warning-input {
  border-radius: 8px !important;
}
.editNote:hover {
  cursor: pointer;
}

.promo-code-popup .ant-space-item:nth-child(1) {
  width: 100%;
}

.export-success-msg .ant-message-notice-content {
  border-radius: 8px;
  background: #f6fef9;
  width: 850px;
}
.export-success-msg .ant-message-success {
  display: flex;
}
.export-success-msg .ant-message-success img {
  margin-right: 10px;
  margin-top: -15px;
}
.promo-code-range-filter-pos {
  left: 160px !important;
}
.promo-code-range-filter .ant-picker-range-arrow {
  display: none;
  border-radius: 8px;
}
.promo-code-range-filter .ant-picker-panel-container {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 345px;
}
.promo-code-range-filter .ant-picker-ranges {
  position: absolute;
  top: 8px;
  left: -140px;
  width: 140px;
  background: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 345px;
  border-right: 1px #eee solid;
  box-shadow: -3px 6px 8px 2px rgba(0, 0, 0, 0.05),
    0 1px 0px 0 rgba(0, 0, 0, 0.08), 0 -9px 0px 0px rgba(0, 0, 0, 0);
  overflow: auto;
}
.promo-code-range-filter .ant-picker-preset {
  width: 100%;
}
.promo-code-range-filter .ant-picker-preset:nth-child(1) {
  margin-top: 10px;
}
.promo-code-range-filter .ant-picker-preset span {
  background: transparent !important;
  color: black !important;
  border: none !important;
  padding: 8px;
  width: 100%;
}
.promo-code-range-filter .ant-picker-preset span:hover {
  background: #f9fafb !important;
  border-radius: 6px;
}
.promo-code-range-filter .ant-picker-footer-extra {
  border-bottom: none;
}
.promo-code-range-filter .ant-picker-header {
  border-bottom: none;
}
.promo-code-range-filter .ant-picker-date-panel:nth-child(1) {
  border-right: 1px solid #f0f0f0;
}
.promo-code-filter {
  border-radius: 8px;
  height: 40px !important;
  width: 100% !important;
}
