.custom-modal .ant-modal-content {
  border-radius: 12px;
}
.internal-note-modal .ant-modal-content {
  width: 343px !important;
  height: 255px !important;
}
.custom-modal .ant-modal-header .ant-modal-title {
  color: #484d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
}
.custom-modal .ant-modal-header {
  border-bottom: 0px;
  padding-bottom: 10px;
  border-radius: 12px 12px 0px 0px;
}

.delete-modal-content .ant-modal-content {
  width: auto !important;
}

.delete-modal-content {
  margin-top: 5px;
}
.delete-modal-content h2 {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.delete-modal-content p {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.delete-reason-modal .ant-modal-title {
  padding-right: 22px;
}

.edit-reason-modal .ant-modal-content {
  width: 100% !important;
}

.ant-dropdown-menu-item-disabled .text-danger {
  color: #ff97a1 !important;
}
.ant-dropdown-menu-item-disabled .text-danger svg > path {
  stroke: #ff97a1 !important;
}
