.list-style {
  list-style: none;
  padding: 0px;
  margin: 0;
  width: 375px;
}
.list-style li {
  color: #484d59;
  word-break: break-all;
}
.font-16 {
  font-size: 16px;
}
/* .userTable .ant-table-content {
  overflow: auto;
} */
.userTable .ant-table-thead > tr > th {
  background: #f9fafb;
  color: #667085;
}
.customClass .ant-table-thead > tr > th {
  text-align: center;
}
.invoice-title h3 {
  color: #484d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 15px;
}
.overdue {
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
}
.subtotal-invoice ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.subtotal-invoice ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  padding: 5px 0px;
}
.subtotal-invoice ul li span {
  display: inline-block;
  width: 50%;
}
.subtotal-invoice ul li span:last-child {
  text-align: right;
}

.subtotal-invoice {
  width: 309px;
}
.border-invoice {
  border-top: 1px solid #d5d5d5;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.591px;
}
.subtotal-invoice ul li.border-invoice span:last-child {
  font-size: 14px;
}
.inputcrm {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  resize: none;
}
.select_payment
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px !important;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd) !important;
  background: var(--Base-White, #fff) !important;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
.select_payment .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #667085;
}
.invoie-send-customer h3 {
  color: #101828;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 93.333% */
}

.invoie-send-customer h4 {
  color: var(--Gray-900, #101828);

  /* Text lg/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.invoie-send-customer label {
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.-mx-12 {
  margin: 0px -12px;
}
.card-invoice p {
  margin: 0px;
}

.address-invoice {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.address-invoice li {
  color: #484d59;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.591px;
  margin-bottom: 10px;
}
.email-template-editable-container {
  border: 1px solid var(--Gray-300, #d0d5dd);
  padding: 10px;
  border-radius: 8px;
}
.email-template-editable-container:focus-visible {
  outline: none;
}

.email-tags
  .ant-select-selection-overflow-item:not(:nth-last-child(2))
  .ant-select-selection-item-content::after {
  content: "" !important;
}

.userTable.customClass.fix-table th {
  white-space: normal;
}

@media only screen and (max-width: 768px) {
  .list-style {
    width: 50%;
  }
  .mobile-table-view {
    overflow: auto;
  }

  .mobile-editor {
    width: 100% !important;
    white-space: normal;
    word-break: break-word;
  }

  .subtotal-invoice {
    width: 100%;
  }
  .customClass {
    overflow: auto;
  }
}
@media only screen and (max-width: 600px) {
  .mobile-achv {
    flex: 1;
  }
}
