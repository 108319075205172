.clear-filter {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 18px;
  height: auto;
}
.fontW {
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.send-customer-btn {
  padding: 0px !important;
  width: fit-content;
}

.send-customer-btn .ant-btn-icon-only.ant-btn-lg {
  border-radius: 8px;
}
.send-customer-btn
  .ant-btn-compact-item.ant-btn-primary:not([disabled])
  + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  top: 0;
  height: 100%;
  background-color: #fff;
  left: 0px;
}
.send-customer-btn
  .ant-btn-compact-item:not(.ant-btn-compact-last-item):not(
    .ant-btn-compact-item-rtl
  ) {
  margin-right: 0px !important;
}

.send-customer-btn .ant-space-compact-block {
  width: fit-content;
}

.send-list-view {
  width: 220px;
  border-radius: 20px;
}
.send-list-view ul {
  border-radius: 8px;
  margin-top: 5px;
}

.send-list-view ul li {
  color: #667085;
}

#pay-with-ach {
  padding: 0px !important;
  border: none !important;
  background: none !important;
}
