.userCard {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.tabs ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.tabs ul li {
  display: inline-block;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 1px 12px 19px 4px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
}

.tabs ul li:hover {
  color: #175cd3;
  border-bottom: 1px solid #175cd3;
}
.tabs ul li.active {
  color: #175cd3;
  border-bottom: 1px solid #175cd3;
}
.mt-20 {
  margin-top: 20px;
}
.btn-filter {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #484d59;
  font-size: 14px;
}
.site-space-compact-wrapper .ant-space {
  width: 100%;
}

.site-space-compact-wrapper .ant-space .ant-space-item:last-child {
  width: 100%;
}

.userlisttabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  justify-content: space-between;
}

.userlisttabs li {
  display: inline-block;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 12px;
  margin-right: 2px;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  text-align: center;
}
.userlisttabs li:last-child {
  margin-right: 0px;
}

.userlisttabs::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.userlisttabs::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}

.userlisttabs::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #667085;
}
.userlisttabs li:hover {
  border-radius: 8px 8px 0px 0px;
  background: #3483fa;
  color: #fff;
}
.userlisttabs li.active {
  border-radius: 8px 8px 0px 0px;
  background: #3483fa;
  color: #fff;
}
.tablecard .ant-table-thead > tr > th {
  color: #667085;
  background: #fff !important;
  white-space: nowrap;
}
.search-assigned {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--base-white, #fff);
  box-shadow: 0px 0px 0px 4px rgba(183, 199, 255, 0.05),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 5px 14px;
}
.assigned .ant-avatar {
  margin-right: 5px;
}

.assigned .ant-tag {
  align-items: center;
  display: flex;
  color: var(--gray-700, #344054);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-family: "Poppins", sans-serif !important;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding: 2px 4px 2px 5px;
}
/* modal css start */
.userlist-modal .ant-modal-content {
  border-radius: 8px;
  padding: 40px 20px 20px;
}

.userlist-modal .ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
  padding: 0px;
  border: 0px;
}

.userlist-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #484d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.userlist-modal .ant-modal-content .ant-modal-body {
  padding: 0px;
}
.lead-check {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
}
.label-status label {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
}
.seletect-option.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.seletect-option .ant-select-selection-placeholder {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.btn-done {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.fliter-sort-title {
  color: #484d59;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}
.ant-slider-tooltip {
  z-index: 99999;
}

.ant-slider-tooltip .ant-tooltip-inner {
  border-radius: 4px;
}

.select-promo-code.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-radius: 8px;
}
.trail-days {
  border-radius: 16px;
  background: #ffbc44;
  padding: 4px 12px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.period {
  border-radius: 50px;
  background: #f5f5f5;
  padding: 4px 20px;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.054px;
  display: inline-flex;
  align-items: center;
}
.close-icon-list {
  position: absolute;
  right: 40px;
  cursor: pointer;
}
.address h6 {
  color: #3483fa;
  font-size: 13px;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.054px;
  margin: 0px;
}

.address p {
  color: #484d59;
  font-size: 13px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.054px;
  margin: 0;
}

.ant-picker-panel .ant-picker-footer {
  display: block !important;
}

.promocode-submit-button,
.promocode-submit-button:hover,
.promocode-submit-button:focus {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #1570ef);
  background: var(--primary-600, #1570ef);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
