.certificate {
  display: table;
  width: 100%;
  white-space: normal;
  word-break: break-all;
}

.certificate h2 {
  font-size: 18px;
  font-weight: 500;
  background: #3483fa;
  padding: 15px;
  outline: none;
  border: 0;
  border-radius: 20px;
  color: #fff;
}

.clientContract a {
  text-decoration: none;
  color: inherit;
}
.certificate ul {
  margin: 10px 0px 15px 10px;
  padding: 0px;
}
@media screen and (max-width: 768px) {
  .certificate ul {
    margin: 0px 0px 15px 10px;
  }
}

.certificate ul li {
  list-style: none;
  line-height: 35px;
  font-size: 16px;
  color: #484d59;
}
.certificate ul li b {
  font-weight: 500;
}
