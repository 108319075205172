@media (min-width: 992px) {
  .user-profile-top {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 992px) {
  .mobile-30 {
    width: 100% !important;
  }
  .w-100.border.p-3.text-content {
    max-width: 100% !important;
  }

  .w-50.mobile-summary {
    width: 100% !important;
  }
  header.ant-layout-header {
    width: 100% !important;
  }

  .dash-titile {
    display: none;
  }

  .rightside ul li svg {
    width: 15px;
  }

  .rightside ul li {
    padding: 0px 5px;
  }

  .main-layout {
    margin-left: 0px !important;
  }

  .logo img {
    width: 170px;
  }

  .ant-breadcrumb-text {
    font-size: 15px;
    letter-spacing: 0px;
    line-height: auto;
  }

  .ant-table-cell.ant-table-cell-fix-left {
    position: relative !important;
    left: auto !important;
  }

  .status-drop {
    width: 100%;
    margin-top: 15px;
  }

  .w-full {
    width: 100% !important;
  }

  .estimation-card .ant-collapse-item:last-child,
  .estimation-card
    .ant-collapse
    > .ant-collapse-item:last-child
    > .ant-collapse-header {
    font-size: 14px;
  }

  .tab-div li {
    font-size: 16px;
    width: 100%;
  }

  .ant-overview-card {
    font-size: 16px;
  }

  .ant-blue-rate-font {
    font-size: 14px;
  }

  .ant-width {
    width: 100%;
    margin-top: 15px;
  }

  .ant-bg-panel .ant-collapse-header {
    font-size: 16px;
  }

  .spacelist .ant-input {
    width: 100% !important;
  }

  .ant-meta-title .ant-card-meta-title {
    font-size: 16px;
  }

  .mobile-width {
    width: 100%;
  }

  .extra-right {
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
  }

  .estimation-card .accordion-button {
    align-items: baseline;
  }

  .accordig-arrow .accordion-button:after {
    top: 19px;
  }

  .closeicon-panel {
    margin-right: 0px;
    margin-left: 10px;
  }
  .ant-search-button {
    max-width: 100%;
  }
  .slide-cs .slick-prev {
    left: 0px;
    background-color: #fff;
    z-index: 1;
  }
  .slide-cs .slick-next {
    right: 0px;
    background-color: #fff;
  }
}
@media only screen and (min-width: 768px) {
  .ant-btn.ant-btn-block.d-lg-width-auto {
    width: auto;
  }
  .text-input-w {
    width: 230px;
  }
  .w-55 {
    width: 55px;
  }
  .attachments-gallery h5 {
    width: 120px;
  }
  .tab-div.invoice-tabs ul {
    padding-right: 15px;
    overflow: hidden;
    overflow-x: auto;
  }
}
@media only screen and (max-width: 768px) {
  .attachments-gallery h5 {
    width: 80px;
  }
  .wm-100 {
    width: 100%;
  }
  .search-width {
    width: auto;
  }
  ul.catlog-tabs.nav:after {
    display: none;
  }
  .chart-wrap .shadow-donut .apexcharts-canvas {
    margin: auto;
  }

  .fillter-responsive span {
    font-size: 13px;
  }

  .fillter-responsive span:last-child {
    margin-right: 0px;
  }

  .fillter-responsive span img {
    width: 20px;
  }

  .fillter-responsive span svg {
    width: 15px;
    height: 15px;
  }

  .fillter-responsive {
    margin-bottom: 5px;
  }
  .pipeline-col {
    width: 100%;
  }
  .user-info {
    margin-top: 0;
    padding: 0px 0px 15px;
  }

  .tab-div {
    padding-right: 10px;
    white-space: nowrap;
  }
  .services-list li {
    padding: 0px;
    margin-bottom: 10px;
  }
  .attachments ul img {
    width: 100%;
  }

  .attachments li > span.ant-dropdown-trigger {
    left: auto;
  }

  .ant-lead-card .ant-collapse-content .ant-collapse-content-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  .attempts-number,
  .attempts-date {
    margin-bottom: 10px;
  }

  .attempts-date span {
    min-width: 60px;
    width: auto;
  }
  .attempts-btn .ant-btn {
    min-width: 180px;
    width: 100%;
  }
  .attempt-history ul li {
    padding-left: 0px;
    border: 0px;
  }

  .attempt-history ul {
    margin-left: 0px;
  }

  .attempt-history ul li .call-icon svg {
    margin-right: 5px;
  }

  .attempt-history-name h6 {
    white-space: nowrap;
  }
  .welcome_main_icon {
    width: 100px;
    margin-bottom: 10px;
  }

  .welcome_message span {
    font-size: 24px;
    display: block;
  }
  .services-list {
    margin: 0px;
  }
  .addfillter span {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .addfillter .anticon {
    font-size: 13px !important;
    margin-top: 5px;
  }
  .modal-radius .ant-modal-header .ant-modal-title {
    font-size: 18px;
  }
  .edit-services-btn .btn-width {
    width: 100%;
  }
  .tab-div.invoice-tabs ul {
    /* padding-right: 15px;
    overflow: hidden;
    overflow-x: auto; */
    gap: 5px;
    flex-direction: column;
  }
  .mobile-overflow {
    overflow: hidden;
    overflow-x: auto;
  }
  .furmulla-tree-box label {
    text-align: left;
  }
  .btn-responsive {
    width: 100%;
  }
  .content-parent
    .content-area
    .content-inner
    .content-text
    .input-send-inner
    .input-send
    .send-inner
    .custom-input {
    width: 100% !important;
  }

  .input-send-inner .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-parent
    .content-area
    .content-inner
    .content-text
    .input-send-inner
    .input-send
    .send-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar-responsive-open {
    display: block;
  }

  .chatswipe-menu {
    position: absolute;
    left: 0px;
    margin-top: -9px;
    font-size: 19px;
  }

  .content-area.mobile-full {
    grid-template-columns: auto !important;
  }

  .chatswipe-menu {
    color: #1a73e8;
    display: block;
  }
  .time-traking-form-table .drop-width {
    width: 100% !important;
  }

  .time-traking-form-table form {
    display: block;
  }
  .trancking-btn {
    width: 50%;
  }
  .datepicker-input {
    flex-direction: column;
  }
  .datepicker-input .datepicker-inner {
    width: 100%;
    margin-bottom: 10px;
  }
  .modal-card .ant-modal-content {
    width: 100%;
  }
  .datebiler {
    max-width: 100% !important;
  }
  .map-tracking-wrap {
    padding: 30px 15px;
  }
  .list-respnsive {
    display: block !important;
  }

  .list-respnsive .ant-list.ant-list-split {
    width: 100% !important;
  }

  .list-respnsive .ant-list-items li button {
    white-space: normal;
    text-align: left;
  }
  .fixspace {
    width: 100% !important;
  }
  .foreman-map-tracking {
    width: 100%;
  }
  .mobile-hourlyrate {
    display: block !important;
  }
  .hourly-moblie {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .addtimesliotbox {
    position: relative;
    overflow-x: auto;
  }
  .module__chiledren__title {
    width: 70% !important;
    margin-left: 0px !important;
  }
  #react-time-range select {
    height: auto !important;
    margin-bottom: 10px !important;
  }

  .module__chiledren__options label {
    margin-right: 0px !important;
    margin-left: -18px !important;
  }
  .invt-amt {
    display: block !important;
    height: auto !important;
    margin-bottom: 16px;
  }
  .fillterint {
    position: relative !important;
    display: block !important;
  }

  .fillterint div {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .invtbox {
    display: block !important;
    margin-left: 0px;
    min-width: 100% !important;
  }
  .movetomobile {
    width: 95% !important;
    padding: 0px 15px;
  }
  .applycrew-m {
    margin-right: 0px !important;
  }
  .timeboxmove {
    padding: 0px 0px !important;
    align-items: center;
  }
  .moveinput {
    width: 100% !important;
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .estimation-card .accordion-button {
    font-size: 15px;
  }
  .contract-preview-pdf-inline {
    width: 100% !important;
  }

  .avatar-container-text h5 {
    font-size: 14px !important;
  }

  .avatar-container {
    width: 50px !important;
    height: auto !important;
  }

  .avatar-container img {
    height: 50px !important;
  }
  .contract-preview-sign-container {
    flex-direction: column;
  }

  .contract-preview-sign-container div {
    width: 100% !important;
  }
  button.ant-btn.company-setting:not(:disabled) {
    margin-top: 15px;
    width: 100%;
  }
  .ant-upload-text {
    padding: 0px 15px;
  }
  .catalogs-estimate h5 {
    font-size: 16px;
  }

  .catalogs-estimate button {
    margin-bottom: 10px;
    margin-left: 0px !important;
    width: 100%;
  }
  .security-key {
    flex-direction: column;
  }

  .security-key input {
    width: 100% !important;
  }
  .social-media-list {
    flex-direction: column !important;
  }

  .social-media-list label {
    width: 100% !important;
    display: block !important;
  }

  .social-media-list div {
    padding: 0px !important;
    margin-top: 15px !important;
    width: 100% !important;
  }
  .paymentbx {
    width: 100%;
  }
  .payment-term-card h5 {
    font-size: 16px;
  }

  .payment-term-card .ant-card-head {
    padding: 0;
  }

  .payment-term-card .ant-card-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  .termsconditions .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
  /* .ant-empty {
    width: calc(100vh - 21rem);
  } */
}

@media only screen and (max-width: 768px) {
  .mobile-100 {
    width: 100%;
  }
  .count-card span,
  .danger-card span,
  .blue-card span {
    font-size: 12px;
  }
  .breadcrumb-box-main {
    padding: 0px 14px;
  }
  .bg-estimates {
    margin: 0px;
    padding: 0px 16px;
  }
  .payment-term-card {
    padding: 0px 5px;
  }
  .extra-right {
    flex-wrap: wrap;
  }
  .some-custom-class {
    padding: 0px;
  }
  ol.report-list.px-17 {
    padding-left: 17.5px;
    padding-right: 17.5px;
  }
  .w-25.mobile-25 {
    width: 100% !important;
  }
  .clear-filter {
    width: 100% !important;
  }
  .apply-btn {
    width: 100% !important;
  }
  .editor-box {
    white-space: normal;
    word-break: break-word;
    margin: 0px 15px 15px;
  }

  .ant-bootom-line-effect.mb-3.estimation-shadow .ant-card-body {
    padding: 12px;
  }

  .ant-bootom-line-effect.mb-3.estimation-shadow
    .ant-card-body
    .ant-meta-title.-mx-3 {
    margin: 0px -12px;
  }
}
.count-card {
  white-space: normal;
  word-break: break-word;
}
.ant-list-bx .ant-list-item-meta-description {
  white-space: nowrap;
}
@media only screen and (max-width: 600px) {
  .export-dropdown {
    min-width: calc(100% - 73px);
    margin-bottom: 10px;
    display: inline-flex;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .ant-list-bx .ant-list-item-meta-description {
    font-size: 12px;
  }
  .ant-list-bx {
    font-size: 12px;
  }
  .ant-blue-rate-font,
  .ant-overview-card {
    font-size: 12px;
  }
}
