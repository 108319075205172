.max-center {
  max-width: 973px;
  margin: auto;
  border-radius: 8.29px;
  background: #fff;
  box-shadow: 0px 3.109px 1.036px 0px rgba(0, 0, 0, 0.04),
    0px 4.145px 4.145px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.mx-973 {
  max-width: 973px;
  margin: auto;
}

.estimate-bg-gray {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: -1.5rem;
  margin-top: -17px;
  background: #f0f0f0;
  padding-top: 15px;
  padding-bottom: 1.5rem;
}

.download-pdf-btn {
  background-image: none;
  border-radius: 8px;
  vertical-align: middle;
  float: inline-end;
  height: 40px;
}

.download-pdf-btn .small_loader {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 3px;
}

.download-pdf-btn .anticon {
  vertical-align: middle;
}

.download-pdf-btn:hover {
  background-image: none;
  border-radius: 8px;
}

.customModal .ant-modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.editor-max-height {
  height: 250px !important;
}

@media screen and (max-width: 768px) {
  .preview-22 {
    margin-right: 0px !important;
  }

  .preview-title {
    padding: 15px 0px !important;
  }

  .preview-title h5 {
    font-size: 14px !important;
  }

  .preview-1 {
    padding: 10px 0px !important;
  }

  .preview-4 {
    padding: 0px !important;
  }

  .preview-5 {
    padding: 15px 0px !important;
  }

  .preview-5 p {
    font-size: 14px !important;
  }

  .preview-2 h5 {
    font-size: 14px !important;
  }

  .preview-2 {
    width: 50% !important;
  }

  .preview-3 {
    width: 50% !important;
  }

  .preview-7 h5 {
    font-size: 14px !important;
  }

  .preview-6 {
    padding: 0px !important;
  }

  .preview-7 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .preview-8 img.ant-image-img {
    height: auto !important;
    width: 95px !important;
    object-fit: cover;
  }

  .preview-8 .ant-image {
    align-items: center;
    display: inline-flex;
  }

  .preview-9 {
    height: auto !important;
  }

  .preview-10 h5 {
    font-size: 14px !important;
  }

  .preview-10 {
    padding-left: 10px !important;
  }

  .preview-11 {
    font-size: 14px;
  }

  .preview-12 ul li {
    font-size: 14px;
  }

  .preview-13 p {
    font-size: 14px;
  }

  .preview-13 table td {
    font-size: 14px;
  }

  .preview-17 {
    width: 100% !important;
  }

  .preview-18 {
    width: 100% !important;
    padding: 6px 0px;
  }

  .preview-19 {
    width: 100% !important;
  }

  .preview-20 {
    width: 100% !important;
    margin-top: 10px;
  }

  .preview-21 {
    width: 100% !important;
    margin-top: 10px;
  }

  .e-signature-modal .ant-tabs-nav-wrap {
    border-radius: 8px;
  }

  .e-signature-modal .ant-tabs-tab {
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  .list-style {
    width: 50%;
  }

  .mobile-table-view {
    overflow: auto;
  }

  .mobile-editor {
    width: 100% !important;
  }

  .subtotal-invoice {
    width: 100%;
  }

  .customClass {
    overflow: auto;
  }

  .mx-973 {
    max-width: 100%;
  }

  div#divToPrint {
    width: 100%;
  }

  .w-50 {
    width: 100% !important;
  }

  .summary {
    width: 100% !important;
  }

  .list-style.list-style-m {
    width: 100%;
  }

  .mobile-min-w {
    min-width: 100% !important;
  }

  .table-setting-box {
    overflow: hidden;
    overflow-x: auto;
  }
}

.defult-class .ant-select-selector {
  border: 0px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
