.main-layout {
  height: 100%;
}
.selectItem span.ant-select-selection-item {
  padding-right: 14px !important;
}

.modal-pop-up .anticon-info-circle {
  font-size: 20px !important;
  float: unset !important;
  margin: 0px 0px 15px 0px !important;
  width: 100% !important;
}

.modal-pop-up .ant-modal-confirm-content {
  margin: 0px !important;
  text-align: center !important;
}

.payout-modal-input-design .ant-input {
  max-width: 75px;
  font-size: 25px;
  font-weight: 500;
  color: #484d59;
}
.payout-modal-input-design .ant-input-prefix {
  margin-right: 0px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  color: #484d59;
}

.payout-modal-input-design.ant-input-affix-wrapper input {
  height: 41px !important;
  padding-left: 0px !important;
}

.my-referral-popup .ant-btn-default,
.my-referral-popup .affiliate-button {
  font-size: 16px;
  font-weight: 500;
}

.payout-request-form,
.payout-request-form .ant-form-item-control-input-content,
.payout-request-form .ant-form-item {
  margin-bottom: 0px !important;
}
/*afflite-marketing css*/
.afflite-market {
  padding: 60px 6rem 1.5rem;
}
.afflite-market h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-top: 10px;
}
.px-12 {
  padding: 0px 12px;
}
.btn-affilite {
  border-radius: 8px;
}
@media only screen and (max-width: 768px) {
  .afflite-market {
    padding: 25px;
  }
  .afflite-market h1 {
    font-size: 24px;
    padding: 20px 0px;
  }
  .company-design {
    position: relative;
    right: inherit;
    float: none;
    bottom: inherit;
    text-align: center;
    padding: 15px 0px 0px;
  }
  .alreadyExist-twoButtons {
    align-items: center;
  }
  .alreadyExist-twoButtons button {
    width: 100%;
  }
  .alreadyExists-emailInput {
    min-height: 240px;
  }
  .gutter-row {
    flex: 0 0 100%;
  }
}
