.form-group .ant-col:first-child {
  padding-right: 12px;
}
.form-group .ant-col:last-child {
  padding-left: 12px;
}
.form-group .ant-btn-primary {
  background-color: #1570ef;
  color: white;
  border: 1px solid #1570ef;
  background-image: none;
  border-radius: 8px;
  height: 44px;
}

.form-group .ant-btn-primary[disabled] {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  height: 44px;
}

.paypal-modal .ant-modal-content {
  height: auto !important;
  width: auto !important;
}

.paypal-modal .paypal-two-inputs-field input.ant-input-disabled,
.paypal-modal .paypal-two-inputs-field textarea.ant-input-disabled,
.payoneer-modal .payoneer-two-inputs-fields input.ant-input-disabled,
.payoneer-modal .payoneer-two-inputs-fields textarea.ant-input-disabled,
.venmo-modal .venmo-two-inputs-field input.ant-input-disabled,
.venmo-modal .venmo-two-inputs-field textarea.ant-input-disabled {
  background: #f5f5f5 !important;
}

.superAdmin-profile-popup
  .ant-form-item-control-input-content
  .ant-btn-danger[disabled] {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #f5f5f5 !important;
  border-radius: 8px;
  height: 40px;
}

.userlist-modal .ant-modal-content {
  padding: 15px 40px 24px 24px;
}
@media only screen and (max-width: 768px) {
  .userlist-modal .ant-modal-content {
    padding: 15px;
  }
}
