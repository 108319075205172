.estimates-headerbar h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #101828;
}

.estimates-headerbar span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #98a2b3;
}

.estimates-headerbar {
  font-family: "Poppins", sans-serif;
}

.estimates-headerbar small {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #667085;
}
.custom-tabs button {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  text-transform: capitalize;
}

.custom-tabs .Mui-selected {
  color: #175cd3;
  background: #eff8ff;
}

.custom-tabs .MuiTabs-indicator {
  border-bottom: 3px solid #175cd3;
}
.edit-modal-radius .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px;
}
.input-edit-field label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #344054;
  font-size: 14px;
}
.input-edit-field input[type="text"] {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  padding: 6px 12px;
  outline: none;
}
.formulla-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #101828;
}
.small-radius.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 30px;
}
span.iconboxpo {
  position: absolute;
  top: 50%;
  left: 3px;
  margin-top: -1px;
  z-index: 1;
  display: none;
  color: #d0d5dd;
}

.iconboxpo-delete {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: 0px;
  display: inline-block;
  color: #d92d20;
}

.iconboxpo-delete svg {
  width: 16px;
  height: 16px;
}
.iconboxpo svg {
  width: 18px;
}
.tiggle-hover:hover .iconboxpo {
  display: inline-block;
}
.tiggle-hover:hover .iconboxpo-delete {
  display: inline-block;
}

.upload-box .upload-btn-estimates {
  border: 1px dashed #d0d5dd;
  color: #344054;
  font-size: 14px;
  padding: 15px;
  flex-direction: column;
  text-transform: initial;
}

.upload-box .upload-btn-estimates small {
  background: #f2f4f7;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.upload-box .upload-btn-estimates:hover {
  background: #f9fafb;
  /* Primary/700 */
  border: 1px dashed #175cd3;
  border-radius: 8px;
  color: #175cd3;
}

.upload-box .upload-btn-estimates:hover small {
  background: #d1e9ff;
  border-radius: 20px;
}
.estimates-cost-title h4 {
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;

  color: #101828;
}

.estimates-cost-title {
  font-family: "Poppins", sans-serif;
}

.estimates-cost-title p {
  font-weight: 400;
  font-size: 14px;

  color: #667085;
  margin: 0;
}
.addnew-estimates-btn {
  background: #1570ef !important;
  border: 1px solid #53b1fd !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.unit-button {
  background: #f2f4f7 !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  color: #344054 !important;
}
.comma {
  margin-left: 5px;
  display: inline-flex;
  /* Commented by Ralph as it returns warning in console */
  align-items: flex-end;
}
.if {
  position: relative;
  top: 15px;
  display: inline-block;
}
.crewtaskmenu {
  border-radius: 8px !important;
  box-shadow: none !important;
}
.crewtaskmenu .crewtask {
  border-radius: 30px;
  background: linear-gradient(134deg, #2375f6, #71f69d);
  text-transform: capitalize;
}

.crewtaskmenu .crew-drop {
  border-radius: 30px;
  background: linear-gradient(134deg, #71f69d, #2375f6);
}

.default-toggle {
  transform: rotate(270deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.default-toggle.active-toggle {
  transform: rotate(180deg);
}
.tasktemplate-view table thead th {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #667085;
}
.tasktemplate-view table tbody th {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #667085;
}
.tasktemplate-view table tbody th:nth-child(2) {
  font-weight: 600;
  color: #101828;
}

.attachment-table .table-head-bg th {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #667085;
}

.attachment-table .tablebody-first th {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #667085;
}
.uploaddiv .ant-upload-list.ant-upload-list-picture-card {
  width: 100%;
}

.uploaddiv
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100%;
  margin-right: 0px;
  height: auto;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
}

.upload-attachment-text {
  padding: 6px 15px;
}

.upload-attachment-text b {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #175cd3;
}

.upload-attachment-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
}

.upload-attachment-text small {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.ant-modal-mask {
  z-index: 1400;
}
.ant-modal-wrap {
  z-index: 1500;
}
.ant-upload.ant-upload-drag {
  background: #fff;
}
.modal-mui-radius .MuiPaper-rounded {
  border-radius: 30px;
}
