.invoice-header-card {
  border-radius: 12px;
  border: 1px solid #f2f4f7;
}

.border-vertical {
  background-color: #a8a8a8;
  width: 1px;
  height: 55px;
}

.back {
  color: #484d59;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
}

.brent-shaw h2 {
  margin: 0px;
  color: #484d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
  letter-spacing: -0.591px;
}

.brent-shaw p {
  color: #484d59;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.591px;
}

.invoice-tabs-details {
  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  border-radius: 6px;
}

.invoice-tabs-details:hover {
  background-color: #3483fa;
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.invoice-active {
  background-color: #3483fa;
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.invoice-select .ant-select-selector {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.project-lead p {
  margin: 0px;
  color: rgba(72, 77, 89, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.591px;
}

.project-lead h4 {
  color: #484d59;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: -0.591px;
  margin: 0px;
}

.project-lead .amount-due {
  color: #3483fa;
  font-size: 27px;
}

.apply-btn {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 18px;
  height: auto;
  font-size: 14px;
}

.apply-btn[type="primary"]:hover,
.apply-btn[type="primary"]:focus {
  border: 1px solid #3483fa;
  background: #3483fa;
  color: #fff;
}

.apply-btn[type="primary"] {
  background: #3483fa;
  border: 1px solid #3483fa;
  color: #fff;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: #d4d4d4 !important;
}

.delete-btn {
  border-radius: 8px;
  border: 1px solid var(--Error-600, #d92d20);
  background: var(--Error-600, #d92d20);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: auto;
  padding: 8px 18px;
}

.delete-btn:hover,
.delete-btn:focus {
  background: var(--Error-600, #d92d20);
  color: var(--Base-White, #fff);
  border: 1px solid var(--Error-600, #d92d20);
}

.accordingToggle-invoice-card {
  width: 100%;
}

.accordingToggle-invoice-card .ant-collapse {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
}

.heading-invoice {
  color: #101828;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.est-line-btn {
  border-radius: 16px;
  background: var(--Primary-50, #eff8ff);
  padding: 5px 8px;
  color: var(--Primary-700, #175cd3);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.rotated-icon {
  transform: rotate(180deg);
  /* Adjust the degree of rotation as needed */
}

.accordingToggle-invoice-card .ant-collapse-arrow {
  transition: transform 0.24s;
}

.accordingToggle-invoice-card .ant-collapse > .ant-collapse-item {
  border-bottom: 0px;
}

.accordingToggle-invoice-card .ant-collapse-content-box {
  padding: 0;
}

.userTable .ant-table-thead > tr > th {
  background: #f9fafb;
  color: #667085;
}

.font-color {
  color: #667085;
}

.overdue {
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
}

.input-crm {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  resize: none;
}

.input-crm .ant-picker-input {
  flex-direction: row-reverse;
}

.input-crm .ant-picker-suffix {
  margin-left: 0px;
  margin-right: 8px;
}

.select-payment
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px !important;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd) !important;
  background: var(--Base-White, #fff) !important;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.select-payment .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #667085;
}

.upload-img {
  height: 250px !important;
}

.upload-img h3 {
  color: #175cd3;
  font-size: 14px;
  margin-bottom: 0px;
}

.upload-img p {
  color: #667085;
  margin: 0px;
  font-size: 12px;
}

.upload-img input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-indent: -5000px;
  cursor: pointer;
}

.label-font label {
  display: block;
  margin-bottom: 5px;
  color: #484d59;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.w-134 {
  width: 134px;
}

.invoiceListDate .ant-select-selector {
  background: #f2f4f7 !important;
  border: none !important;
}

.receipt-list {
  /* border: 1px solid #ccc; */
  position: absolute;
  bottom: 45px;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 180px;
  overflow-y: auto;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

.receipt-list li {
  height: auto !important;
}

.delete-box li.delete {
  color: #e42626;
  font-weight: 500;
}

.apply-btn.ant-btn[disabled],
.apply-btn.ant-btn[disabled]:hover,
.apply-btn.ant-btn[disabled]:focus,
.apply-btn.ant-btn[disabled]:active {
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  background: #f5f5f5;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: rgba(102, 112, 133, 0.25);
}

.delete-modal-content {
  flex: 1;
}

.overlay-modal-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
}

.delete-icon-btn {
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
  display: none;
}

.previewImg:hover .delete-icon-btn {
  display: block;
}

.previewImg:hover .overlay-modal-view {
  display: block;
}

.close-button-img {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgb(0 0 0 / 40%);
  padding: 10px;
  border-radius: 5px;
  width: 90px;
  justify-content: space-between;
}

.alert-div {
  color: #b54708;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #f9dbaf;
  background: #fffcf5;
  padding: 16px;
}

.alert-div span {
  text-decoration-line: underline;
  display: inline-block;
  cursor: pointer;
}

.ant-input-disabled.input-crm.readonly-input {
  border: 0px;
  background: transparent;
  color: #344054;
  font-size: 14px;
}

.estimation-change-order-table .ant-table-summary {
  position: sticky;
  bottom: 0;
  box-shadow: 0 0 10px 1px #f1f1f1;
}

.summary-content {
  width: 80% !important;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.button-dropdown .ant-btn-compact-first-item {
  display: none;
}

.button-dropdown .ant-dropdown-trigger {
  border: hidden;
  background: transparent;
}

.invoice-dates svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.ant-picker-panel-container {
  border-radius: 8px !important;
}

.ant-picker-header,
.ant-picker-footer {
  border: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #1890ff !important;
  border-radius: 50% !important;
}

.ant-picker-cell-inner {
  border-radius: 50% !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.ant-picker-header button {
  color: #667085 !important;
}

.dueDate-popup .ant-picker-panel {
  margin-top: 95px;
}

.allocation-modal .ant-modal {
  width: max-content !important;
}

.allocation-modal .ant-modal-header {
  padding-right: 40px;
}

.multiple-receipt-badge {
  border-radius: 100%;
  width: 17px;
  height: 17px;
  background: #f2f4f7;
  color: #3483fa;
  font-size: 10px;
}

.public-DraftStyleDefault-block {
  margin-top: 0px;
}

.summary-item {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.text-content {
  white-space: normal;
  word-break: break-word;
}

.text-table-td {
  color: #667085;
}

.defult-class .ant-select-selector {
  border: 0px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.defult-class .ant-select-selector {
  font-size: 18px;
  color: #484d59;
}

.defult-class .ant-select-selector span {
  font-weight: 600;
}
