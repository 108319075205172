.receipt-found {
  background: #f0f2f5;
}
.page-not-title h1 {
  color: var(--Gray-900, #101828);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
}
.page-not-title p {
  color: var(--Gray-500, #667085);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
