.tooltipText {
  font-weight: 600;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}
.labeAlignLeft .ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label::after {
  content: "";
}

.center-error-message:where(.ant-form-item) .ant-form-item-explain-error {
  text-align: center;
}

.input-otp .input-otp__field {
  height: 80px;
  min-width: 14%;
  font-size: 48px;
  font-weight: 500;
}
.input-otp .ant-input::placeholder {
  text-overflow: ellipsis;
  font-size: 48px;
  font-weight: 500;
  border-radius: 10px;
}

.google-address .css-yk16xz-control {
  background-color: #f5f5f5 !important;
}

.google-api-address .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border-radius: 8px !important;
  align-items: center !important;
}

.warning-shadow {
  height: 48px;
  width: 48px;
  background-color: #fee4e2;
  border: 8px solid #fef3f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.superAdmin-profile-popup .ant-modal-content {
  border-radius: 12px;
  width: 82%;
  margin: auto;
}

.superAdmin-profile-popup .ant-form {
  margin-top: 20px;
}

.superAdmin-profile-popup .ant-form-item-control-input-content {
  margin: 0px;
}
.superAdmin-profile-popup .ant-form-item {
  margin-bottom: 9px;
}

.superAdmin-profile-popup .ant-form-vertical svg {
  margin-right: 10px;
  font-size: 18px;
}

.superAdmin-profile-popup .ant-modal-title {
  font-size: 20px;
  color: #667085;
  text-align: center;
}

.superAdmin-profile-popup .ant-modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0px;
}

.superAdmin-profile-popup .ant-modal-body {
  padding-bottom: 7px !important;
}

.superAdmin-profile-popup input {
  width: 100%;
  height: 41px;
  outline: none;
  border: none;
  padding-left: 15px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.superAdmin-profile-popup .ant-input-affix-wrapper {
  width: 100%;
  height: 43px;
  outline: none;
  border: none;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 0px 0px 0px 10px !important;
}

.superAdmin-profile-popup .ant-input-affix-wrapper input {
  height: 38px;
  padding-left: 5px;
}

.superAdmin-profile-popup .ant-form-item-control-input-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.superAdmin-profile-popup .ant-form-item-control-input-content .ant-btn {
  width: 47% !important;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
}

.ant-btn-default:hover {
  color: unset;
}

.superAdmin-profile-popup
  .ant-form-item-control-input-content
  .ant-btn-primary {
  background-color: #1570ef !important;
  background-image: none;
  color: white;
  border: 1px solid #1570ef !important;
}
.superAdmin-profile-popup .ant-form-item-control-input-content .ant-btn-danger {
  background-color: #e42626 !important;
  background-image: none;
  color: white;
  border: 1px solid #bc2c2c !important;
}

.superAdmin-profile-popup .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1890ff;
  border-top: 0;
  border-left: 0;
}
.superAdmin-profile-popup .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #1890ff;
}

.modal-form-password .ant-form-item-control-input-content {
  flex-direction: column;
}

.modal-form-password .ant-form-item-explain-error {
  margin: 0px;
}
.modal-form-password .ant-form-item-explain-error:last-child {
  margin-bottom: 10px;
}
.inner-social-content {
  position: relative;
}
.social-error {
  position: absolute;
  top: 100%;
  left: 0;
}
@media only screen and (max-width: 992px) {
  .profile-input-divs .forName input {
    width: 100%;
  }
  .responsiveFor-button button {
    margin-left: 0px;
  }
  .profile-input-divs .allbox-forPassword .ant-input-affix-wrapper {
    width: 100%;
  }
  .profile-input-divs .allbox-forEmail .ant-input-affix-wrapper {
    width: 100%;
  }
  .rowFirst h5 {
    font-size: 20px;
  }
  .rowSecond {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 0px;
  }
  .upload-Id .upload-Id-progressBar .progressBar-inner-forFlex,
  .upload-w9Form .upload-w9Form-progressBar .progressBar-inner-forFlex {
    justify-content: center;
    flex-wrap: wrap;
  }
  .upload-Id .upload-Id-progressBar .inner-progressBar,
  .upload-w9Form .upload-w9Form-progressBar .inner-progressBar {
    flex-wrap: wrap;
  }
  .upload-Id .upload-Id-progressBar .fileName .photoName,
  .upload-w9Form .upload-w9Form-progressBar .fileName .photoName {
    font-size: 12px;
    text-align: center;
  }
  .upload-Id .ant-progress .ant-progress-outer,
  .upload-w9Form .ant-progress .ant-progress-outer {
    width: 100%;
    padding-right: 0px;
  }
  .drawerMenu {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .drawerMenu li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
    font-size: 16px;
    cursor: pointer;
  }
  .profile-input-divs .allbox-forPhone .ant-input-affix-wrapper {
    width: 100%;
  }
  .css-yk16xz-control {
    width: 100%;
  }
  .full-width-s {
    width: 100%;
  }
  .col-lastchild:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .my-referal-banner {
    background-image: none;
    padding: 0px;
    border: 0px;
  }
  .req-icon {
    width: 35px;
    height: 35px;
    padding: 0px !important;
  }
}
