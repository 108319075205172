.plan-title-content h2 {
  color: #101828;
  font-size: 32px;
}
.plan-title-content p {
  color: #667085;
  font-size: 18px;
}
.plan-change-estimation {
  border-radius: 8px;
  border: 1px solid #eaecf0;
}
.plan-change-estimation h3 {
  color: #3483fa;
  font-size: 28px;
  margin-bottom: 20px;
}

.plan-change-estimation h4 {
  font-size: 60px;
  font-weight: 600;
}

.plan-change-estimation h4 sub {
  font-size: 20px;
  margin-right: 5px;
}

.plan-change-estimation h4 small {
  font-size: 14px;
  color: #667085;
  display: inline-block;
  position: relative;
  top: 5px;
}

.plan-change-estimation p {
  color: #667085;
}
.button-plan {
  border: 1px solid #eaecf0;
  font-weight: 500;
  font-size: 16px;
  height: auto;
  padding: 8px;
  border-radius: 8px;
}
.features-content ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;
}

.features-content ul li {
  display: block;
  padding: 5px 0px;
  color: #667085;
  font-size: 16px;
}

.features-content ul li svg {
  margin-right: 8px;
}
.button-plan.apply-btn:hover {
  background: #3483fa;
  color: #fff;
}
.change-title {
  font-size: 22px;
  color: #101828;
  font-weight: 600;
}
.estomation-plan-text {
  color: #667085;
  font-size: 14px;
}
.month-text {
  font-size: 14px;
  color: #344054;
  font-weight: 500;
}
.month-text-active {
  color: #3483fa;
  font-weight: 500;
  font-size: 14px;
}
.iconselect {
  position: absolute;
  right: 18px;
  width: 20px;
  height: 20px;
  color: #fff;
  background: #3483fa;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 100%;
  top: 10px;
}
.convert-content p {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
}

.click-btn {
  border: 0px;
  border-bottom: 1px solid;
  padding: 0;
  margin-left: 5px;
  line-height: 18px;
  height: auto;
  color: #3483fa;
}

.ant-modal.userlist-modal.trial-free-modal .ant-modal-content {
  padding: 24px;
}
