.proposal-cards {
  min-height: 1056px !important;
  width: 816px !important;
}
.contract-toggle .ant-collapse-header {
  padding: 0px !important;
}
.contract-toggle .ant-collapse-content-box {
  padding-left: 0px;
  padding-right: 0px;
}
.company-logo {
  height: 116.35px !important;
  width: 250px !important;
}
.contract-toggle .ant-collapse-header .ant-collapse-header-text {
  font-weight: 600;
  font-size: 18px;
}
.contract-heading h2 {
  color: #484d59;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.855px;
  letter-spacing: -0.591px;
}
.contract-heading ul {
  margin: 0;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 600;
  font-size: 14px;
}

.contract-heading ul li {
  list-style: none;
}
.contract-heading {
  border-bottom: 1px solid #d0d5dd;
  padding-bottom: 15px;
}
.contract-bg-section {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 15px;
}
.contract-img {
  position: relative;
}
.contrct-title {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 57%,
    rgba(0, 0, 0, 0.9) 100%
  );
  position: absolute;
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 25px;
}

.contrct-title h3 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 90px !important;
  font-style: normal;
  font-weight: revert;
  line-height: 99px !important; /* 110% */
  letter-spacing: 5px;
}
.pro_btn {
  border-radius: var(--Number, 0px) var(--Number, 0px) 12px 12px;
  background: #3483fa;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.855px; /* 159.274% */
  letter-spacing: -0.591px;
  height: auto;

  width: 438px;
}
.probtn-section {
  text-align: center;
  margin-top: -1px;
}
.overtext {
  padding: 80px 50px;
}
.overtitle h4 {
  color: #3d414a;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.855px; /* 127.42% */
  letter-spacing: -0.591px;
}

.overtitle p {
  color: #727887;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 250% */
  letter-spacing: -0.591px;
  margin-bottom: 0px;
}

.contct-address {
  color: #727887;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 156.25% */
  letter-spacing: -0.591px;
}
.abouts-btn {
  border-radius: var(--Number, 0px) 12px 12px var(--Number, 0px);
  background: #3483fa;
  color: #fff;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.855px; /* 91.014% */
  letter-spacing: -0.591px;
  height: 70px;
}
.aboutContent {
  margin-top: 24px;
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and
  (-webkit-appearance: none) {
  .aboutContent h2 p {
    color: #484d59;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 159.091% */
  }

  .aboutContent p {
    color: #484d59;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
  }

  .aboutContent h5 {
    color: #484d59;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
}
.aboutContent h2 p {
  color: #484d59;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 159.091% */
}

.aboutContent p {
  color: #484d59;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.aboutContent h5 {
  color: #484d59;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.upload-ab-contract {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  padding: 24px;
  text-align: center;
}

.upload-ab-contract input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
}

.upload-ab-contract {
  position: relative;
}
.upload-ab-contract h6 {
  color: var(--Primary-700, #175cd3);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin: 0;
}

.upload-ab-contract h6 span {
  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.upload-ab-contract p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin: 0;
}
.p-30 {
  padding: 30px !important;
}
.scop-work h2 {
  color: #484d59;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.855px; /* 127.42% */
  letter-spacing: -0.591px;
  border-bottom: 1px solid #888;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.contract-dg {
  border-radius: 8px;
  overflow: hidden;
  background: #3483fa;
}
.contract-dg {
  border-radius: 8px;
  overflow: hidden;
  background: #3483fa;
}

.contract-dg h4 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.855px; /* 176.972% */
  letter-spacing: -0.591px;
}
.contactCount {
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.855px; /* 176.972% */
  letter-spacing: -0.591px;
}
ul.list-contract li {
  color: #484d59;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 166.667% */
  margin-top: unset;
}

ul.list-contract {
  margin-top: 15px;
  padding-left: 22px;
}

.paverbox td:first-child,
.paverbox th:first-child {
  width: auto;
}

.paverbox .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.paverbox td,
.paverbox th {
  padding: 10px 15px;
  border-bottom: 1px solid #9f9f9f !important;
  color: #484d59;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.855px; /* 187.382% */
  letter-spacing: -0.591px;
  white-space: nowrap;
}

.paverbox td:last-child,
.paverbox th:last-child {
  text-align: right;
}
.expanded td:last-child,
.expanded th:last-child {
  text-align: center !important;
}
.ad-contract h4 {
  color: #484d59;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 136.364% */
}

.ad-contract p {
  color: #484d59;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.customer_section {
  border-bottom: 1px solid #d9d9d9;
}

.customer_section label {
  display: block;
  margin-bottom: 15px;
  color: #484d59;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.591px;
}

input.customer_name {
  border: 0px;
  outline: none;
  background: transparent;
}

.chrome-picker {
  box-shadow: none !important;
  width: 100% !important;
}
.select-contarct-w {
  width: 15.1vw;
}
@media screen and (max-width: 1024px) {
  .select-contarct-w {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .max-width-832 {
    max-width: 832px;
  }
}

#primary_color {
  border-radius: 6px;
  height: 25px;
  width: 25px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  box-shadow: none;
}

#primary_color::-webkit-color-swatch-wrapper {
  padding: 0;
}
#primary_color::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
  box-shadow: none;
}

/* ========================about us ========================== */
.bg-about-contact {
  position: relative;
  margin: 0px -24px -24px -24px;
}
.bg-about-contact:after {
  content: "";
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 62.66%,
    rgba(255, 255, 255, 0.85) 90.93%,
    #fff 100%
  );
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-about-contact img {
  width: 100%;
  object-fit: cover;
}

.formstate label {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formstate input {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #484d59;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.formstate .ant-form-item {
  margin-bottom: 6px;
}

.cancel-btn {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 10px 12px;
  height: auto;
}
.save-defult-btn {
  border-radius: 8px;
  border: var(--Number, 1px) solid #3483fa !important;
  background: #3483fa !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 10px 16px;
  height: auto;
  box-shadow: none !important;
}
.main-modal-block h2 {
  color: var(--Gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.delete-iconb {
  cursor: pointer;
}
.delete-iconb svg {
  width: 25px;
  fill: #fff;
  cursor: pointer;
}

.delete-iconb svg path {
  fill: #fff;
}
.img-card {
  position: relative;
  width: 150px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.img-card img {
  display: inline-block;
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.overlay-img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #0000004d;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formstate textarea.ant-input {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  resize: none;
  height: 85px;
}
.application-link input::placeholder {
  color: #3483fa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.chrome-picker {
  box-shadow: none !important;
}

.ant-uloadf {
  color: var(--Primary-700, #175cd3);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.ant-upload-text {
  color: var(--Gray-500, #667085);
  text-align: center;

  /* Text xs/Normal */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.ant-upload.ant-upload-drag.upload-img-card {
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}
.h-44 {
  height: 44px;
}
.expanded .ant-table-row-expand-icon-cell {
  margin-left: -64px;
}
.expanded td,
.expanded th {
  font-size: 12px !important;
}
.paverbox .ant-table-expanded-row-level-1,
.paverbox .ant-table-expanded-row-level-1 > .ant-table-cell,
.expanded .ant-table-expanded-row-level-1,
.expanded .ant-table-expanded-row-level-1 > .ant-table-cell {
  background: transparent;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-card.proposal-card-h-fix {
  min-height: 1056px !important;
  min-width: 816px;
  max-width: 816px;
}
@media screen and (min-width: 992px) {
  .sticky-sidebar {
    position: sticky;
    top: 62px;
    z-index: 3;
  }
}
@media (max-width: 1270px) {
  .ant-card.proposal-card-h-fix {
    min-width: auto !important;
  }
}
.proprosol-card-h {
  min-height: 476px;
}
.estimate-line-item-photo {
  border: none !important;
}
.estimate-line-item-photo .ant-upload.ant-upload-btn {
  padding: 0px !important;
}
.title-editor-class {
  font-size: 16px !important;
  overflow: unset !important;
  margin: 4px 0px !important;
}
.title-wrapper-class {
  border-radius: 8px !important;
  height: 58px !important;
  padding: 4px 11px !important;
  border: 1px solid #ccc !important;
  color: #484d59 !important;
}
.title-editor-class .public-DraftStyleDefault-block {
  margin: 0px !important;
}
.editor-class .rdw-editor-main {
  max-height: 300px !important;
}
.hideData tbody {
  display: none;
}
.proposal_table_card .ant-table-thead > tr > th {
  color: #667085;
  background: #f9fafb !important;
  white-space: nowrap;
}
.equipment_proposal_table_expend
  .ant-table-tbody
  > tr.ant-table-placeholder
  td {
  border-bottom: 0px !important;
}
.mobile-view-proposal-header {
  display: none;
}
.proposal_filter_btn {
  display: none;
}
@media screen and (min-width: 992px) {
  .ant-card.proposal-card-h-fix {
    min-height: 1049px !important;
  }
}
@media screen and (max-width: 992px) {
  .ant-card.proposal-card-h-fix2 {
    min-height: 509px !important;
  }
  .desktop-view-proposal-header {
    display: none;
  }
  .mobile-view-proposal-header {
    display: block;
  }
  .mobile-cus {
    display: none;
  }
  .ant-card.proposal-card-h-fix {
    min-width: auto !important;
  }
  .clear-filter.proposal_btn_clear2 {
    padding: 8px 10px !important;
    border: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  .proposal_mobile_header_card2 {
    background: transparent;
  }
  .clear-p2 {
    padding: 8px 10px !important;
  }
  .mobile_icon_proposal {
    display: none;
  }
  .copy_proposal_btn2 {
    background: #3483fa;
    color: #ffffff;
    width: 44px;
    height: 44px;
    border-radius: 5px;
  }

  .copy_proposal_btn2 svg path {
    stroke: #fff;
  }
  .proposal_filter_btn2 {
    width: 44px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #d0d5dd;
    padding: 10px;
    display: inline-block;
  }
  .select-contarct-w2.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 44px;
  }

  .select-contarct-w2.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item {
    line-height: 44px;
  }
  .mobile_proposal_setting_card2 {
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    margin-top: 10px;
  }
  .contrct-title2 h3 {
    font-size: 28px !important;
    line-height: 36px !important;
  }
  .pro_btn-1 {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    border-radius: var(--Number, 0px) var(--Number, 0px) 12px 12px;
  }
  .overtext {
    padding: 20px;
  }

  .abouts-btn2 {
    padding: 16px !important;
    font-size: 18px;
    height: auto;
    line-height: 22px;
  }

  .company-logo2 {
    height: auto !important;
    width: 80px !important;
  }

  .overtext2.p-30 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .imgbox-contract2 {
    width: 45px !important;
  }

  .contactCount2 {
    font-size: 14px;
  }

  .contract-dg2 h4 {
    font-size: 15px;
  }
  .overtitle2 h4 {
    font-size: 10px;
  }
  .overtitle2 p {
    font-size: 8px;
    line-height: 14px;
  }
  .contct-address2 {
    font-size: 8px;
    line-height: 14px;
    overflow-wrap: break-word;
  }
  .aboutContent2 h2 p {
    font-size: 16px;

    line-height: 24px;
  }
  .aboutContent2 p {
    font-size: 16px;
    line-height: 26px;
  }
  .ad-contract2 p {
    font-size: 16px;
    line-height: 26px;
    overflow-wrap: break-word;
  }
  .ad-contract2 h4 {
    font-size: 18px;
  }
  .customer_section2 label {
    font-size: 16px;
  }
  input.customer_name2 {
    border: 0px;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 8px !important;
  }
  img.cover_img2 {
    height: auto !important;
    /* object-fit: none !important; */
  }

  .add-btn.proposal_btn2 {
    padding: 8px !important;
  }
  .aboutImg_covr2 {
    height: auto !important;
  }
  .pasimg2 {
    height: auto !important;
  }
}
.about-content-editor h1,
.about-content-editor h1 span,
.about-content-editor h2,
.about-content-editor h2 span,
.about-content-editor h3,
.about-content-editor h3 span,
.about-content-editor h4,
.about-content-editor h4 span,
.about-content-editor h5,
.about-content-editor h5 span,
.about-content-editor h6,
.about-content-editor h6 span {
  color: #484d59 !important;
  font-size: 18px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  text-align: left !important;
}
.editor-class h1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
@media screen and (max-width: 768px) {
  .editor-class h1 {
    font-size: 8.691px;
    line-height: 14.485px;
  }
  .about-content-editor h1,
  .about-content-editor h1 span,
  .about-content-editor h2,
  .about-content-editor h2 span,
  .about-content-editor h3,
  .about-content-editor h3 span,
  .about-content-editor h4,
  .about-content-editor h4 span,
  .about-content-editor h5,
  .about-content-editor h5 span,
  .about-content-editor h6,
  .about-content-editor h6 span {
    font-size: 8.691px !important;
    line-height: 14.485px !important;
  }
  .editor-class2 h1 {
    font-size: 8.691px;
    line-height: 14.485px;
  }
  .about-content-editor2 h1,
  .about-content-editor2 h1 span,
  .about-content-editor2 h2,
  .about-content-editor2 h2 span,
  .about-content-editor2 h3,
  .about-content-editor2 h3 span,
  .about-content-editor2 h4,
  .about-content-editor2 h4 span,
  .about-content-editor2 h5,
  .about-content-editor2 h5 span,
  .about-content-editor2 h6,
  .about-content-editor2 h6 span {
    font-size: 8.691px !important;
    line-height: 14.485px !important;
  }
  .pro_btn-1 {
    font-size: 9.657px;
    font-weight: 500;
    line-height: 15.381px; /* 159.274% */
    letter-spacing: -0.285px;
    height: 24.142px;
    width: 211px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--Number, 0px) var(--Number, 0px) 12px 12px;
  }
  .overtext2 {
    padding-top: 38px;
  }
  .abouts-btn2 {
    padding: 9.657px 28.971px !important;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.381px; /* 91.014% */
    letter-spacing: -0.285px;
  }
  .aboutContent2 h2 {
    color: #484d59;
    font-size: 10.623px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.9px; /* 159.091% */
  }
  .aboutContent2 p {
    color: #484d59;
    font-size: 8.691px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 14.485px !important;
  }
  .aboutContent-flex2 {
    display: flex;
    flex-direction: column;
    min-height: 387px;
  }
  .financing2 {
    font-size: 9px !important;
    font-weight: 500;
    line-height: 24px !important;
    text-align: left;
  }
  .contract-dg2 h4 {
    font-size: 8.691px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.381px; /* 176.972% */
    letter-spacing: -0.285px;
  }

  .contactCount2 {
    color: #fff;
    text-align: right;
    font-size: 8.691px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.381px; /* 176.972% */
    letter-spacing: -0.285px;
  }

  img.ant-image-img2 {
    height: 19.314px !important;
  }

  .contract-dg2 {
    min-height: 19.314px !important;
  }

  ul.list-contract2 li,
  ul.list-contract2 {
    color: #484d59;
    font-size: 7.243px !important;
    font-weight: 400;
    line-height: 12.071px; /* 166.667% */
    letter-spacing: -0.285px;
  }
  .paverbox2 td {
    color: #484d59;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.855px; /* 398.186% */
    letter-spacing: -0.591px;
  }
  .paverbox2 td,
  .paverbox2 th {
    padding: 10px 2px;
  }

  .proposal_table_card2 .ant-table-thead > tr > th {
    color: #484d59;
    font-size: 8px;
    font-style: normal;
    line-height: 12.855px; /* 398.186% */
    letter-spacing: -0.591px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .paverbox2 .ant-table-tbody > tr > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .paverbox2 .ant-table tfoot > tr > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .scope_work2 {
    color: #484d59;
    font-size: 10.623px !important;
    font-style: normal;
    font-weight: 600;
    /* 144.795% */
    letter-spacing: -0.285px;
  }
  .materialbox2 {
    font-size: 10.623px !important;
  }
  .scope-work2 h4 {
    color: #484d59;
    font-size: 10.623px !important;
    font-style: normal;
    font-weight: 600;
    /* 144.795% */
    letter-spacing: -0.285px;
  }
  .ad-contract2 h4 {
    color: #484d59;
    font-family: Poppins;
    font-size: 10.623px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.9px; /* 159.091% */
  }

  .ad-contract2 p,
  /* .ad-contract span, */
  .ad-contract2,
  .termsNCondition2 span,
  .termsNCondition2 p,
  .termsNCondition2 p span {
    color: #484d59;
    font-family: Poppins;
    font-size: 8.691px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 14.485px; /* 166.667% */
  }

  .termsNCondition2 strong,
  .termsNCondition2 h4 span,
  .termsNCondition2 p,
  .termsNCondition2 p span {
    color: #484d59;
    font-family: Poppins;
    font-size: 8.691px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 14.485px; /* 166.667% */
  }

  .attachment2 {
    height: 418px !important;
  }

  .customer_section2 label,
  .ad-contract2 label {
    color: #484d59;
    font-family: Poppins;
    font-size: 9.657px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.285px;
  }

  .add-btn.proposal_btn2 {
    font-size: 8.691px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.588px; /* 133.333% */
    height: 21px;
    padding: 0px 8px !important;
  }
  .aboutContent2 {
    color: #484d59;
    font-size: 8.691px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.485px; /* 166.667% */
  }
  .proprosol-card-h2 {
    min-height: 282px !important;
  }
  .total-summary2 {
    color: #484d59;
    font-size: 8.691px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.381px; /* 176.972% */
    letter-spacing: -0.285px;
  }
  .customer_section2 img {
    height: auto !important;
  }
  .customer_section2 .label-2 {
    margin-bottom: 10px !important;
  }
  .total-summary2 {
    padding: 14px 2px 10px !important;
  }
}

.table-name-field {
  text-align: left !important;
}

.hide-borders td {
  border-bottom: none !important;
  padding-bottom: 0px !important;
}

.hide-last-row-border tbody tr:last-child td {
  border-bottom: none !important;
  padding-bottom: 0px !important;
}
.wrap-name {
  white-space: normal !important;
  line-height: normal !important;
}
.main-table-data-field {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.main-table-data-field:first-child {
  padding-left: 0px !important;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }
  #divToPrint {
    margin: 0;
    padding: 0;
  }
}
.h-560 {
  height: 560px;
}
